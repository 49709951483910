import React, { useState, useEffect } from "react";
import ProductCard from "../ProductCard";
import { produits } from "../../Data/data";
import { Pagination } from "@mui/material";
import "./style.css";

export default function AllProducts({ sousFamille, famille, bigFamille }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [singleProduct, setSingleProduct] = useState(false);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage(12);
      } else if (windowWidth >= 700) {
        setItemsPerPage(9);
      } else {
        setItemsPerPage(6);
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);


  const filteredProducts = produits.filter((produit) => {
    if (sousFamille && famille && bigFamille) {
      return (
        decodeURIComponent(sousFamille) === produit.sousCategorie &&
        decodeURIComponent(famille) === produit.categorie &&
        bigFamille === produit.famille
      );
    } else if (sousFamille && famille) {
      return (
        sousFamille === produit.sousCategorie && famille === produit.categorie
      );
    } else if (famille && bigFamille) {
      return famille === produit.categorie && bigFamille === produit.famille;
    } else if (sousFamille) {
      return sousFamille === produit.sousCategorie;
    } else if (famille) {
      return famille === produit.categorie;
    } else if (bigFamille) {
      return bigFamille === produit.famille;
    } else {
      return true;
    }
  });
  useEffect(() => {
    // Vérifier si vous avez un seul produit
    setSingleProduct(currentPage === 1 && filteredProducts.length === 1);
  }, [currentPage, filteredProducts]);

  const indexOfLastCard = currentPage * itemsPerPage;
  const indexOfFirstCard = indexOfLastCard - itemsPerPage;
  const currentCards = filteredProducts.slice(
    indexOfFirstCard,
    indexOfLastCard
  );
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div
        className={`allProduct ${
          singleProduct ? "singleProduct" : ""
        }`}
      >
        {currentCards.map((produit, index) => (
          <ProductCard
            key={produit.articleName + index}
            categorie={produit.categorie}
            sousCategorie={produit.sousCategorie}
            articleName={produit.articleName}
            ext={produit.ext}
            famille={produit.famille}
            familyName={sousFamille}
            promo={produit.promo}
            newProduct={produit.new}
            code={produit.Code}
            brand={produit.brand}
            pcouleurs={produit.pCouleurs}
            system={produit.systeme}
          />
        ))}
      </div>
      {totalPages > 1 && (
        <div className="pagination">
          <Pagination
            count={totalPages}
            page={currentPage}
            shape="rounded"
            variant="outlined"
            onChange={(event, newPage) => handlePageChange(newPage)}
            color="primary"
            size="small"
          />
        </div>
      )}
    </div>
  );
}
