import React from "react";
import "./style.css";
import anime from "animejs";
import { useEffect } from "react";
import showroom from "../../images/showroomHome.jpg";
import Carousel from "../../Components/Carousel";
import CardProduit from "../../Components/CardProduit";
import ProductExample from "../../Components/ProductExample";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import MailIcon from "@mui/icons-material/Mail";
import Footer from "../../Components/Footer";
import ScrollToTop from "../../Components/scrollup";
import sanitana from "../../images/sanitana.png";
import presto from "../../images/presto.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';
export default function Home() {
  useEffect(() => {
    const track_id="G-H62WN1HJEK";
  ReactGA.initialize(track_id);
  ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  function checkVisibility() {
    var elements = document.querySelectorAll(".footer");

    elements.forEach((element) => {
      var elementPosition = element.getBoundingClientRect().top;
      var windowHeight = window.innerHeight;

      if (
        elementPosition < windowHeight &&
        !element.classList.contains("is-visible")
      ) {
        element.classList.add("is-visible");
        element.classList.remove("hidden");

        anime({
          targets: element,
          translateX: [400, 0],
          opacity: [0, 1],
          scale: [0, 1],
          delay: anime.stagger(400, { start: 400 }),
          easing: "easeInOutQuad",
        });
      }
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
  }, []);

  return (
    <div className="home" id="acceuil">
      <ScrollToTop />
      <div>
        <div className="homeTop">
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/sanitek-tn.appspot.com/o/logo2.png?alt=media&token=5c89a3b7-b532-45b6-8523-35af9fc8639b" alt="sanitek" className="homeLogo" />
            <h3 className="homeTitle">
              sanitek - Excellence en services sanitaires depuis 2011
            </h3>
            <p className="homeP">
              Sanitek est une société SARL créée en 2011, issue d'une profonde
              expérience dans les secteurs du sanitaire, de la robinetterie et
              de la cuisine. Elle est la première entreprise en Tunisie à
              importer une vaste gamme d'éléments en céramique de la marque
              portugaise Sanitana, tout en distribuant à des établissements
              publics, des hôtels, des administrations et des hôpitaux la
              robinetterie la plus économique en eau de marque française
              "PRESTO". Jouissant d'une forte notoriété auprès des
              professionnels et des particuliers, Sanitek garantit la qualité et
              l'innovation pour le client final
            </p>
          </div>
          <div>
            <Carousel />
          </div>
        </div>
      </div>
      <div className="presentation">
        <h2>Nos Gammes de produits </h2>
        <p>Découvrez notre gamme variée de produits sanitaires répondant à toutes vos exigences. Qualité, style et satisfaction assurés.</p>
        <CardProduit />
      </div>
      <div className="presentation ">
        <h2>Nouveautés </h2>
        <div className="newProduct">
          <ProductExample sousFamille="sanitaire ceramique" />
        </div>
      </div>
      <div className="presentation ">
        <h2>nos principaux fournisseurs</h2>
        <div className="frnsDiv">
          <div className="frnsDiv2">
            <img src={sanitana} alt="sanitana" className="frnsImg2" />
            <p>
              Sanitana est une entreprise située à Anadia , au Portugal Fondée
              au 1979.Spécialisé dans le developpement de produits Sanitaires
              par la salle de bain
            </p>
            <Link to="fournisseurs&references">
              <Button
                endIcon={<ArrowRightAltIcon />}
                sx={{ fontFamily: "Quicksand" }}
                size="small"
                variant="outlined"
              >
                Je découvre
              </Button>
            </Link>
          </div>
          <div className="frnsDiv2">
            <img src={presto} alt="presto" className="frnsImg2" />
            <p>
            Depuis 1927, PRESTO Spécialiste de la robinetterie électronique, temporisée et sensitive pour les collectivités
            </p>
            <Link to="fournisseurs&references">
              <Button
                endIcon={<ArrowRightAltIcon />}
                sx={{ fontFamily: "Quicksand" }}
                size="small"
                variant="outlined"
              >
                Je découvre
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="presentation ">
        <h3 className="presentationTitle3">+1000 produits disponibles </h3>
        <div className="productIcon">
          {icons.map((icon, index) => (
            <div key={index} className="iconContainer">
              <img
                src={require(`../../images/icon/${icon}.png`)}
                alt={icon}
                className="iconImg"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="presentation ">
        <div className="showroom">
          <div className="showroomImgContainer">
            <img src={showroom} alt="showroom" className="showroomImg anime" />
          </div>
          <div className="showroomDiv">
            <h4>notre showroom à sfax</h4>
            <span>Route de Tunis cité el Ons Sakiet ez Zit, Tunisia, 3021</span>
            <a href="tel:74861655">
              <PhoneIcon
                sx={{
                  width: { lg: "18px", md: "16px", sm: "14px", xs: "14px" },
                }}
              />{" "}
              &nbsp; (+216) 74 861 655
            </a>
            <a href="tel:99993011">
              <PhoneAndroidIcon
                sx={{
                  width: { lg: "18px", md: "16px", sm: "14px", xs: "14px" },
                }}
              />{" "}
              &nbsp; (+216) 99 993 011
            </a>
            <a href="mailto:commercial@sanitek.tn">
              <MailIcon
                sx={{
                  width: { lg: "18px", md: "16px", sm: "14px", xs: "14px" },
                }}
              />{" "}
              &nbsp; commercial@sanitek.tn
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
const icons = ["1", "2", "3", "4", "5", "6", "7"];
