import "./style.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
export default function Footer() {
  return (
    <div className="footer">
      <hr></hr>
      <div className="foots">
        <div className="foot">
          <img src="https://firebasestorage.googleapis.com/v0/b/sanitek-tn.appspot.com/o/logo2.png?alt=media&token=5c89a3b7-b532-45b6-8523-35af9fc8639b" alt="logo" className="footer-logo" />
          <a href="mailto:commercial@sanitek.tn" className="footer-link">
          commercial@sanitek.tn
          </a>
        </div>
        <div className="foot">
          <h4> Informations </h4> 
          <p className="footer-link">
         <strong style={{color:"#011"}}> adresse</strong><br/><br/>
         Route de Tunis cité el Ons prés du Tribunal, Sakiet ez Zit, Tunisia, 3021
           </p>
           <p className="footer-link">
             <strong style={{color:"#011"}}> horaires</strong><br/><br/>
             LUN – VEND: 08:00 À 18:00<br/>
                 SAM: 08:00 À 15:00
           </p>
        </div>

        <div className="foot">
          <h4>contactus</h4>
          <a href="tel:74861655" className="footer-link">
          (+216) 74 861 655
          </a>
          <a href="tel:99993011" className="footer-link">
          (+216) 99 993 011
          </a>
          <div>
          <a
            href="https://www.facebook.com/profile.php?id=61557594144687"
            className="footer-link"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon sx={{color:'#008DBD',width:{lg:36,md:'34px',sm:'24px',xs:'20px'}}}/>
          </a>
          <a
            href="https://www.instagram.com/entreprisesanitek/"
            className="footer-link"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon sx={{color:'#008DBD',width:{lg:36,md:'32px',sm:'22px',xs:'18px'}}}/>
          </a>
          </div>
        </div>
      </div>
     
      <hr></hr>
      <div className="lastFoot">
        <h5 style={{ color: "GrayText" }}>
          © 2024, SANITEK. All Rights Reserved.
        </h5>
      </div>
    
    </div>
  );
}
