/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import React from "react";
import Footer from "../../Components/Footer";
import ScrollToTop from "../../Components/scrollup";
import ProductNav from "../../Components/productNav";
import { produits } from "../../Data/data";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, Dialog } from "@mui/material";
export default function ProductDetails() {
  const [open, setOpen] = React.useState(false);
  const [openTech, setOpenTech] = React.useState(false);
  const handleClickOpenTech = () => {
    setOpenTech(true);
  };

  const handleCloseTech = () => {
    setOpenTech(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let pathName = window.location.pathname;
  let pathe = pathName.replace(/%20/g, " ");
  let path1 = pathe.split("/")[2];
  let path2 = pathe.split("/")[3];
  let path3 = pathe.split("/")[4];
  let path5 = pathe.split("/")[5];
  let path4 = pathe.split("/")[5].replace(/-/g, " ");
  let productName = path5.split("-")[1];

  return (
    <div className="products" id="produits">
      <div className="product">
        <ScrollToTop />
        <div className="nav">
          <ProductNav />
        </div>
        <div className="productContain">
          <h2 className="pathTitle">{decodeURIComponent(path3)}</h2>
          <div>
            {produits.map((produit, index) =>
              produit.articleName === decodeURIComponent(path5) ? (
                <div className="productcardDetails">
                  <img
                    onClick={handleClickOpen}
                    className="cardDetailsImg"
                    src={`https://firebasestorage.googleapis.com/v0/b/sanitek-tn.appspot.com/o/produits%2F${encodeURIComponent(
                      path1
                    )}%2F${encodeURIComponent(path2)}%2F${encodeURIComponent(
                      decodeURIComponent(path4)
                    )}.jpg?alt=media`}
                    title={path3}
                    alt={path3}
                  />
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    onBackdropClick={handleClose}
                    sx={{ zIndex: 9990888 }}
                  >
                    <img
                      className="cardMediaDialog"
                      src={`https://firebasestorage.googleapis.com/v0/b/sanitek-tn.appspot.com/o/produits%2F${encodeURIComponent(
                        path1
                      )}%2F${encodeURIComponent(path2)}%2F${encodeURIComponent(
                        decodeURIComponent(path4)
                      )}.jpg?alt=media`}
                      title={path3}
                      alt={path3}
                    />
                  </Dialog>
                  <div className="morecardDetail">
                    <h2>{decodeURIComponent(productName)} </h2>
                    {produit.type?(
                    <span>
                      <Button
                        sx={{
                          fontSize: {
                            lg: "15px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                        }}
                        startIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        <strong>Type :</strong>
                      </Button>
                      {produit.type}
                    </span>):(<></>)}
                    {produit.brand?(
                    <span>
                      <Button
                        sx={{
                          fontSize: {
                            lg: "15px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                        }}
                        startIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        <strong>Marque :</strong>
                      </Button>
                      <span>
                      {produit.brand}
                      </span>
                    </span>):(<></>)}
                    {produit.ficheTech?(
                    <>
                   <img
                    onClick={handleClickOpenTech}
                    className="cardDetailsImgTech"
                    src={`https://firebasestorage.googleapis.com/v0/b/sanitek-tn.appspot.com/o/produits%2F${encodeURIComponent(
                      path1
                    )}%2F${encodeURIComponent(path2)}%2F${encodeURIComponent(
                      decodeURIComponent(path4)
                    )}-1.jpg?alt=media`}
                    title={path3}
                    alt={path3}
                  />
                  <Dialog
                    open={openTech}
                    onClose={handleCloseTech}
                    onBackdropClick={handleCloseTech}
                    sx={{ zIndex: 9990888 }}
                  >
                    <img
                      className="cardMediaDialogTech"
                      src={`https://firebasestorage.googleapis.com/v0/b/sanitek-tn.appspot.com/o/produits%2F${encodeURIComponent(
                        path1
                      )}%2F${encodeURIComponent(path2)}%2F${encodeURIComponent(
                        decodeURIComponent(path4)
                      )}-1.jpg?alt=media`}
                      title={path3}
                      alt={path3}
                    />
                  </Dialog>
                      
                    </>):(<></>)}
                    {produit.Code?(
                    <span>
                      <Button
                        sx={{
                          fontSize: {
                            lg: "15px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                        }}
                        startIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        <strong>Code :</strong>
                      </Button>{" "}
                      {produit.Code}
                    </span>):(<></>)}
                  
                    {produit.Couleur?(
                    <span>
                      <Button
                        sx={{
                          fontSize: {
                            lg: "15px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                        }}
                        startIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        <strong>Couleur :</strong>
                      </Button>{" "}
                      {produit.Couleur}
                    </span>):(<></>)}
                    {produit.Matiere?(
                    <span>
                      <Button
                        sx={{
                          fontSize: {
                            lg: "15px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                        }}
                        startIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        <strong>Matière :</strong>
                      </Button>{" "}
                      {produit.Matiere}
                    </span>):(<></>)}
                    {produit.systeme?(
                    <span>
                      <Button
                        sx={{
                          fontSize: {
                            lg: "15px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                        }}
                        startIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        <strong>SYSTÈME :</strong>
                      </Button>{" "}
                      {produit.systeme}
                    </span>):(<></>)}
                    {produit.hauteur?(
                    <span>
                      <Button
                        sx={{
                          fontSize: {
                            lg: "15px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                        }}
                        startIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        <strong>HAUTEUR STANDARD :</strong>
                      </Button>{" "}
                      {produit.hauteur}
                    </span>):(<></>)}
                    {produit.verre?(
                    <span>
                      <Button
                        sx={{
                          fontSize: {
                            lg: "15px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                        }}
                        startIcon={<ChevronRightIcon fontSize="small" />}
                        >
                          <strong>Verre :</strong>
                        
                      </Button>{" "}
                      <br/>
                      <span className="dimension" dangerouslySetInnerHTML={{ __html: produit.verre }} />
                    </span>):(<></>)}
                    {produit.pCouleurs?(
                    <span>
                      <Button
                        sx={{
                          fontSize: {
                            lg: "15px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                        }}
                        startIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        <strong>PROFIL COULEURS :</strong>
                      </Button>{" "}
                      {produit.pCouleurs}
                    </span>):(<></>)}
                    {produit.dimension?(
                    <span >
                      <Button
                        sx={{
                          fontSize: {
                            lg: "15px",
                            md: "13px",
                            sm: "11px",
                            xs: "11px",
                          },
                        }}
                        startIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        <strong>Dimensions :</strong>
                      </Button>{" "}
                    
                      <span className="dimension" dangerouslySetInnerHTML={{ __html: produit.dimension }} />
                    </span>):(<></>)}
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
