import * as React from 'react';
import './style.css';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import KeyboardArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { cardData } from '../../Data/cardData';
export default function ProductNav() {
  const [openIndex, setOpenIndex] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [path1, setPath1] = React.useState('');
  const [path2, setPath2] = React.useState('');
  const [path3, setPath3] = React.useState('');
  React.useEffect(() => {
    let pathName = window.location.pathname;
    let pathe = pathName.replace(/%20/g, ' ');
    let newPath1 = pathe.split('/')[2];
    let newPath2 = pathe.split('/')[3];
    let newPath3 = pathe.split('/')[4];
    setPath1(newPath1);
    setPath2(newPath2);
    setPath3(newPath3);
  }, []); 

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  
  const handleSubItemClick = (index, subIndex) => {
    setActiveIndex(activeIndex === subIndex ? null : subIndex);
  };

  return (
    <div className='navBarMenu'>
       <span className='navbarCollection'>
        collection 
        {path1 && <a href={`/collections/${path1}`}>{` / ${path1}`} </a>}  
        {path2 && <a href={`/collections/${path1}/${path2}`}>{` / ${decodeURIComponent(path2)}`}  </a>} 
        {path3 && <a href={`/collections/${path1}/${path2}/${path3}`}>{` / ${decodeURIComponent(path3)}`}  </a>} 
       </span>
      <List
        sx={{ width:{lg:'25vw',md:'30vw',sm:'30vw',xs:'35vw'}, bgcolor: 'background.paper',display:'flex',flexDirection:'column',alignItems:'center' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
       {cardData.map((category, index) => (
          <React.Fragment key={index}>
            <ListItemButton onClick={() => handleClick(index)} sx={{bgcolor:'#f1f1f1',borderTop:'4px solid',borderTopColor:`#${index}${index+1}${index+4}DBD`,display:'flex',flexDirection:'column',alignItems:'center',width:{lg:'25vw',md:'35vw',sm:'32.5vw',xs:'35vw'},marginTop:2,textAlign:'center'}}>
              <ListItemText  />
              {category.title === 'serie enfants' ? (
                <a href={`/collections/${category.title}`} className={`categoryTitle ${path1 === category.title ? 'redText' : ''}`}>
                  {category.title}
                </a>
              ) : (
                <span className={`categoryTitle ${path1 === category.title ? 'redText' : ''}`}>
                  {category.title}
                </span>
              )}
            </ListItemButton>
            {openIndex === index && category.sousFamille.length > 0 && (
              <Collapse in={true} timeout="auto" unmountOnExit key={category.title+index}>
                <List component="div" disablePadding sx={{display:'flex',flexDirection:'column',alignItems:'flex-start',bgcolor:'#f1f1f1',width:{lg:'25vw',md:'35vw',sm:'32.5vw',xs:'35vw'},borderTop:'1px solid',borderTopColor:'#0000003E'}}>
                  {category.sousFamille.map((item, subIndex) => (
                    item.nom!==''?(
                    <React.Fragment key={subIndex}>
                      <ListItemButton onClick={() => handleSubItemClick(index, subIndex)} >
                      {item.sousFamilles.length > 0 ? (
    activeIndex===subIndex ? (<ExpandLessIcon fontSize='small'/>) : (<ExpandMoreIcon fontSize='small'/>)
) : (
  <KeyboardArrowRightIcon fontSize='small'/>
)}

                        <ListItemText  />
                        <span className={`subfamilyItem ${(activeIndex === index || path2 === item.nom) ? '' : ''}`}>
                        {item.sousFamilles.length === 0 ? (
    <a href={`/collections/${category.title}/${item.nom}`} className={`subfamilyItem ${( path2 === item.nom) ? 'select' : ''}`}>
        {item.nom}
    </a>
) : (
    <span className={`subfamilyItem ${(path2 === item.nom) ? 'select' : ''}`}>
        {item.nom}
    </span>
)}
                        </span>
                      </ListItemButton>
                      {activeIndex === subIndex && item.sousFamilles.length > 0 && (
                        <Collapse in={true} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding sx={{display:'flex',flexDirection:'column',alignItems:'flex-start',bgcolor:'#f1f1f1',width:{lg:'25vw',md:'35vw',sm:'32.5vw',xs:'35vw'},borderBottom:'1px solid',borderBottomColor:'#0000003E'}}>
                            {item.sousFamilles.map((subItem, subSubIndex) => (
                               subItem!==''?(
                              <ListItemButton key={subSubIndex}  >
                                <KeyboardArrowRightIcon fontSize='small'/>
                                <ListItemText />
                                <a href={`/collections/${category.title}/${item.nom}/${subItem}`} className='subsubfamilyItem'>
                                  <span className={` ${(activeIndex === index && path3 === subItem) ? 'subSelect' : ''}`}>{subItem}</span>
                                </a>
                              </ListItemButton>):(<></>)
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </React.Fragment>
                    ):(<></>)
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
       ))}
      </List>
    </div>
  );
}

