import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import ProductCard from '../ProductCard';
import { produits } from '../../Data/data';
import './style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function ProductExample({ sousFamille }) {
  const [itemsPerPage, setItemsPerPage] = useState(4);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage(4);
      } else if (windowWidth >= 700) {
        setItemsPerPage(3);
      } else {
        setItemsPerPage(2);
      }
    };

    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);

    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: itemsPerPage,
    slidesToScroll: 1,
    className: 'custom-slider', 
  };
  
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {produits
          .filter((produit) =>   produit.new===true)
          .map((produit, index) => (
            <ProductCard
            key={produit.articleName + index}
            categorie={produit.categorie}
            sousCategorie={produit.sousCategorie}
            articleName={produit.articleName}
            ext={produit.ext}
            famille={produit.famille}
            familyName={sousFamille}
            promo={produit.promo}
            new={produit.new}
            />
          ))}
      </Slider>
    </div>
  );
          }