/* eslint-disable jsx-a11y/iframe-has-title */
import "./style.css";
import React from "react";
import contactImg from "../../images/showroom.jpg";
import Footer from "../../Components/Footer";
import ScrollToTop from "../../Components/scrollup";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
export default function Contact() {
  return (
    <div className="contact" id="contact">
      <ScrollToTop />
      <div className="contactContain">
        <h2 className="locationTitle">contactez-nous</h2>
        <div className="contactDiv">
          <div className="contactDiv2">
            <h3 className="contacth3">Contact:</h3>
            <span>
              <PhoneAndroidIcon
                sx={{
                  color: "#008DBD",
                  width: { lg: "50px", md: "50px", sm: "32px", xs: "22px" },
                }}
              />{" "}
              <a href="tel:74861655" className="contact-link">
                (+216) 74 861 655
              </a>
            </span>
            <span>
              <PhoneIcon
                sx={{
                  color: "#008DBD",
                  width: { lg: "50px", md: "50px", sm: "32px", xs: "22px" },
                }}
              />
              <a href="tel:99993011" className="contact-link">
                (+216) 99 993 011
              </a>
            </span>
            <span>
              <MailIcon
                sx={{
                  color: "#008DBD",
                  width: { lg: "50px", md: "50px", sm: "32px", xs: "22px" },
                }}
              />{" "}
              <a href="mailto:commercial@sanitek.tn" className="contact-link">
                commercial@sanitek.tn
              </a>
            </span>
          </div>
          <img src={contactImg} alt="façade" className="contactImg" />
        </div>
        </div>
        <div className="contactContain">
        <h2 className="locationTitle">notre emplacement</h2>
        <div className="contactDiv">
          <div className="contactDiv3">
            <LocationOnIcon
              sx={{
                width: { lg: "72px", md: "50px", sm: "32px", xs: "24px" },
                color: "#008DBD",
              }}
            />
            <p className="locationP">
              ROUTE DE TUNIS CITÉ EL ONS PRÉS DU TRIBUNAL, SAKIET EZ ZIT,
              TUNISIA, 3021
            </p>
            </div>
            <iframe
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3274.9049258176706!2d10.756788675026472!3d34.833482872872764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1301d31c8ed85505%3A0x55c53e1843a88774!2sSte%20Sanitek!5e0!3m2!1sfr!2stn!4v1705681134437!5m2!1sfr!2stn"
            style={{ border: 0, borderRadius: "8px" }}
            loading="lazy"
          ></iframe>
        
        </div>
      </div>
      <Footer />
    </div>
  );
}
