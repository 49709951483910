import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';

function Carousel() {
  const slides = ['1', '2', '3', '4','5','6','7'];



  const settings = {
    focusOnSelect: true,
    pauseOnHover: false,
    dots: true,
    appendDots: dots => (
      <div
        style={{
          borderRadius: "0px",
          
        }}
      >
        <ul className='dotsUl'> {dots} </ul>
      </div>
    ),

      
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: false,
  };

  return (
    <div className='carouselHome' style={{ position: 'relative' }}>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/sanitek-tn.appspot.com/o/slide%2F${encodeURIComponent(slide)}.jpg?alt=media`}
            alt='carousel' />
            
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Carousel;
