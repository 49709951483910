import "./style.css";
import { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import { Button, IconButton, ThemeProvider, createTheme } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import { Link, useLocation } from "react-router-dom";
import { cardData } from "../../Data/cardData";
import ReactGA from 'react-ga';
const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1280,
    },
  },
});

function Header() {
  useEffect(() => {
    const track_id="G-H62WN1HJEK";
  ReactGA.initialize(track_id);
  ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSubDropdown, setShowSubDropdown] = useState(false);
  const [showSubSubDropdown, setShowSubSubDropdown] = useState(false);
  const handleCollectionsHover = () => {
    setShowDropdown(true);
  };

  const handleCollectionsLeave = () => {
    setShowDropdown(false);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [activeLink, setActiveLink] = useState("home");
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubCategory, setActiveSubCategory] = useState(null); 

  // ...
  const handleSubCategoryClick = (subCategoryTitle) => {
    setActiveSubCategory(activeSubCategory === subCategoryTitle ? null : subCategoryTitle);
  };
  // Fonction pour gérer le clic sur une catégorie
  const handleCategoryClick = (categoryTitle) => {
    setActiveCategory(activeCategory === categoryTitle ? null : categoryTitle);
  };


  const location = useLocation();

  const currentPath = location.pathname;

  useEffect(() => {
    setActiveLink(currentPath === "/" ? "home" : currentPath.slice(1));
  }, [currentPath]);
  
  useEffect(() => {
      const pageTitle = location.pathname.split('/').pop();
      if (pageTitle===""){
        // eslint-disable-next-line no-useless-concat
        document.title =  'SANITEK'; 
      }else{
      document.title = `${pageTitle.replace(/%20/g, ' ')}`; 
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <ThemeProvider theme={customTheme}>
      <div className="header" id="header">
        <div className="navbar">
     
          <img src="https://firebasestorage.googleapis.com/v0/b/sanitek-tn.appspot.com/o/logo2.png?alt=media&token=5c89a3b7-b532-45b6-8523-35af9fc8639b" alt="sanitek" className="logo" />
          <div className="right-nav">
            <ol className={isOpen ? "none" : "linkList"}>
              <li>
                <Link
                  to="/"
                  className={
                    activeLink === "home" ? "navLink active" : "navLink"
                  }
                >
                  ACCUEIL
                </Link>
              </li>
              <li className="collectionLink">
  <div
    className={
      currentPath.includes("collections")
        ? "navLink active"
        : "navLink"
    }
    onMouseEnter={handleCollectionsHover}
    onMouseLeave={handleCollectionsLeave}
  >
    Collections
    <div className={`dropdown ${showDropdown ? "active" : ""}`}>
      <ul>
        {cardData.map((category) => (
          <li key={category.title}>
            {category.sousFamille.length === 0 ? (
              <a
                href={`/collections/${category.title.toLowerCase()}`}
                className={
                  activeLink === category.title.toLowerCase()
                    ? "navLink active"
                    : "navLink down"
                }
              >
                {category.title}
              </a>
            ) : (
              <div
                className={
                  activeLink === "collections"
                    ? "navLink active"
                    : "navLink"
                }
                onMouseEnter={() =>
                  setShowSubDropdown(category.title.toLowerCase())
                }
                onMouseLeave={() => setShowSubDropdown("")}
              >
                 <a style={{marginLeft:'-15px'}}
                href={`/collections/${category.title.toLowerCase()}`}
                className={
                  activeLink === category.title.toLowerCase()
                    ? "navLink active"
                    : "navLink down"
                }
              >
                {category.title}
                </a>
                {showSubDropdown === category.title.toLowerCase() && (
                  <div
                    className="subDropdown"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "100%",
                    }}
                  >
                    <ul>
                      {category.sousFamille.map((sousFamille) => (
                        <li key={sousFamille.nom}>
                          <a
                            href={`/collections/${category.title.toLowerCase()}/${sousFamille.nom.toLowerCase()}`}
                            className={
                              activeLink === "collections"
                                ? "navLink active"
                                : "navLink"
                            }
                            onMouseEnter={() =>
                              setShowSubSubDropdown(sousFamille.nom.toLowerCase())
                            }
                            onMouseLeave={() =>
                              setShowSubSubDropdown(showSubSubDropdown)
                            }
                          >
                            {sousFamille.nom}
                          </a>
                          {showSubSubDropdown === sousFamille.nom.toLowerCase() && (
                            <div
                              className="subDropdown"
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "100%",
                              }}
                            >
                              <ul>
                                {sousFamille.sousFamilles.map((subSubFamille) => (
                                  <li key={subSubFamille}>
                                    <a
                                      href={`/collections/${category.title.toLowerCase()}/${sousFamille.nom.toLowerCase()}/${subSubFamille.toLowerCase()}`}
                                      className={
                                        activeLink === "collections"
                                          ? "navLink active"
                                          : "navLink"
                                      }
                                    >
                                      {subSubFamille}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  </div>
</li>

              <li>
                <Link
                  className={
                    activeLink === "promotions" ? "navLink active" : "navLink"
                  }
                  to="/promotions"
                >
                  Promotions
                </Link>
              </li>
              <li>
                <Link
                  className={
                    activeLink === "fournisseurs&references"
                      ? "navLink active"
                      : "navLink"
                  }
                  to="/fournisseurs&references"
                >
                  fournisseurs & références
                </Link>
              </li>
              <li>
                <Link
                  className={
                    activeLink === "contacts" ? "navLink active" : "navLink"
                  }
                  to="/contacts"
                >
                  Contacts
                </Link>
              </li>
              <li>
                <div className="socialLinks">
                  <a
                    href="https://www.instagram.com/entreprisesanitek/"
                    target="_blank"
                    rel="noreferrer"
                    className="socialLink"
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61557594144687"
                    target="_blank"
                    rel="noreferrer"
                    className="socialLink"
                  >
                    <FacebookIcon />
                  </a>
                  <a href="mailto:commercial@sanitek.tn" className="socialLink">
                    <EmailIcon />
                  </a>
                </div>
              </li>
            </ol>
          </div>
          <IconButton
            sx={{
              display: {
                lg: "none",
                md: "none",
                sm: "inline-block",
                xs: "inline-block",
              },
              top: 2,
              right: "10px",
              zIndex: 99,
            }}
            onClick={toggleMenu}
          >
            {!isOpen ? (
              <MenuIcon
                id="openIcon"
                sx={{ color: "#008DBD" }}
                fontSize="small"
              />
            ) : (
              <CloseIcon
                id="closeIcon"
                sx={{ color: "#008DBD" }}
                fontSize="medium"
              />
            )}
          </IconButton>
          <div className={isOpen ? "linkMenu show" : "linkMenu"}>
            <ol id="navMenu">
              <li>
                <a
                  className={
                    activeLink === "home" ? "navLink active" : "navLink"
                  }
                  href="/"
                >
                  Acceuil
                </a>
              </li>
              <li className="separation"></li>
              <li>
  <div
    className={
      currentPath.includes("collections")
        ? "navLink active"
        : "navLink"
    }
  >
    Collections
  </div>
  <ul className="sousCatLink">
    {cardData.map((category) => (
      <li key={category.title}>
        {category.sousFamille.length === 0 ? (
          <Button
            sx={{ color: 'white' }}
            component={Link}
            to={`/collections/${category.title.toLowerCase()}`}
            className={
              activeLink === category.title.toLowerCase()
                ? "navLink active"
                : "navLink down"
            }
          >
            {category.title}
          </Button>
        ) : (
          <>
            <Button
              sx={{ color: 'white' }}
              className={
                activeLink === category.title.toLowerCase()
                  ? 'navLink active'
                  : 'navLink down'
              }
              startIcon={<AddIcon style={{ fontSize: 14 }} />}
              onClick={() => handleCategoryClick(category.title.toLowerCase())}
            >
              {category.title}
            </Button>
            {activeCategory === category.title.toLowerCase() && (
              <ul className="subCategoryLink">
                {category.sousFamille.map((sousFamille) => (
                 Boolean(sousFamille.nom) && ( <li key={sousFamille.nom}>
                    {sousFamille.sousFamilles.length === 0? (
                      <Button
                        sx={{ color: 'wheat' }}
                       
                       
                        className={
                          activeLink === sousFamille.nom.toLowerCase()
                            ? 'navLink active'
                            : 'navLink down'
                        }
                      >
                        <a href={`/collections/${category.title.toLowerCase()}/${sousFamille.nom.toLowerCase()}`}
                          className={
                            activeLink === sousFamille.nom.toLowerCase()
                              ? 'navLink active'
                              : 'navLink down'
                          }>
                        {sousFamille.nom}
                        </a>
                      </Button>
                    ) : (
                      <>
                        <Button
                          sx={{ color: 'wheat' }}
                          className={
                            activeLink === sousFamille.nom.toLowerCase()
                              ? 'navLink active'
                              : 'navLink down'
                          }
                          startIcon={<AddIcon style={{ fontSize: 12 }} />}
                          onClick={() => handleSubCategoryClick(sousFamille.nom.toLowerCase())}
                        >
                          {sousFamille.nom}
                        </Button>
                        {activeSubCategory === sousFamille.nom.toLowerCase() && (
                          <ul className="subSubCategoryLink">
                            {sousFamille.sousFamilles.map((subSubFamille) => (
                            Boolean(subSubFamille) && (  <li key={subSubFamille}>
                                <Button
                                  sx={{ color: 'wheat' }}
                                 
                                 
                                >
                                  <a  className={
                                    activeLink === subSubFamille.toLowerCase()
                                      ? 'navLink active'
                                      : 'navLink down'
                                  } href={`/collections/${category.title.toLowerCase()}/${sousFamille.nom.toLowerCase()}/${subSubFamille.toLowerCase()}`}>
                                  {subSubFamille}
                                  </a>
                                </Button>
                              </li>)
                            ))}
                          </ul>
                        )}
                      </>
                    )}
                  </li>)
                ))}
              </ul>
            )}
          </>
        )}
      </li>
    ))}
  </ul>
</li>

              <li className="separation"></li>
              <li>
                <a
                  className={
                    activeLink === "promotions" ? "navLink active" : "navLink"
                  }
                  href="/promotions"
                >
                  promotions
                </a>
              </li>

              <li className="separation"></li>

              <li>
                <a
                  className={
                    activeLink === "fournisseurs&references"
                      ? "navLink active"
                      : "navLink"
                  }
                  href="/fournisseurs&references"
                >
                  fournisseurs & références
                </a>
              </li>
              <li className="separation"></li>
              <li>
                <a
                  className={
                    activeLink === "contacts" ? "navLink active" : "navLink"
                  }
                  href="/contacts"
                >
                  Contacts
                </a>
              </li>
              <li className="separation"></li>
              <li>
                <div className="socialLinks">
                  <a
                    href="https://www.instagram.com/entreprisesanitek/"
                    target="_blank"
                    rel="noreferrer"
                    className="socialLink"
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61557594144687"
                    target="_blank"
                    rel="noreferrer"
                    className="socialLink"
                  >
                    <FacebookIcon />
                  </a>
                  <a href="mailto:commercial@sanitek.tn" className="socialLink">
                    <EmailIcon />
                  </a>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
export default Header;
