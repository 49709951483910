export const produits = [
  /*---------------------------------------------salles de bain---------------------------------------------------*/

  /*---------------------------------------------sanitaire ceramique---------------------------------------------------*/
  /*---------------------------------------------cuvette suspendu---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette suspendue",
    articleName: "cuvette suspendue-be you",
    type: "cuvette suspendue",
    dimension: "370 x 540 x 400 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    brand: "Sanitana",
    Code: "S10062518200000",
    promo: true,
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette suspendue",
    articleName: "cuvette suspendue-corail",
    type: "cuvette suspendue",
    dimension: "365 x 550 x 400 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    brand: "Sanitana",
    Code: "S10202481700000",
  },

  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette suspendue",
    articleName: "cuvette suspendue-glam",
    type: "cuvette suspendue",
    dimension: "360 x 525 x 400 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    brand: "Sanitana",
    Code: "S10200882100000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette suspendue",
    articleName: "cuvette suspendue-pop art",
    type: "cuvette suspendue",
    dimension: "360 x 545 x 400 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    brand: "Sanitana",
    Code: "S10078751000000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette suspendue",
    articleName: "cuvette suspendue-pop square",
    type: "cuvette suspendue",
    dimension: "355 x 530 x 400 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    brand: "Sanitana",
    Code: "S10204082200000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette suspendue",
    articleName: "cuvette suspendue-Munique",
    type: "cuvette suspendue",
    dimension: "360 x 540 x 410 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    brand: "Sanitana",
    Code: "S10073651000000",
  },
  /*---------------------------------------------cuvette au sol---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette au sol",
    articleName: "cuvette au sol-Munique",
    type: "Cuvette Compacte jusqu’au Mur 68 S/H",
    dimension: "380 x 680 x 785 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    brand: "Sanitana",
    Code: "S10012723400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette au sol",
    articleName: "cuvette au sol-Be You",
    type: "Cuvette Compacte jusqu’au Mur",
    dimension: "370 x 640 x 790 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    brand: "Sanitana",
    Code: "S10062423300000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette au sol",
    articleName: "cuvette au sol-Corail",
    type: "Cuvette Compacte jusqu’au Mur avec Double Sortie",
    dimension: "370 x 620 x 780 m",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    brand: "Sanitana",
    Code: "S10063881700000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette au sol",
    articleName: "cuvette au sol-Glam",
    type: "Cuvette Compacte jusqu’au Mur",
    dimension: "370 x 620 x 780 m",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    brand: "Sanitana",
    Code: "S10063881700000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette au sol",
    articleName: "cuvette au sol-POP Art",
    Code: "S10076923300000",
    type: "Cuvette Compacte jusqu’au Mur",
    dimension: "355 x 640 x 790 mm",
    Couleur: "Blanc Pur",
    brand: "Sanitana",
    Matiere: "Céramique",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "cuvette au sol",
    articleName: "cuvette au sol-Pop",
    brand: "Sanitana",
    type: "Cuvette Compacte jusqu'au Mur 64 S/H",
    dimension: "365 x 640 x 785 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10076823400000",
  },
  /*---------------------------------------------vasque a poser---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-Arena 38",
    brand: "Sanitana",
    type: "Vasque à Poser 38",
    dimension: "380 x 380 x 128 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10009050400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-Arena 70",
    brand: "Sanitana",
    type: "Vasque à Poser 70",
    dimension: "700 x 380 x 128 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10009150400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-CUP 41",
    brand: "Sanitana",
    type: "Vasque à Poser 41",
    dimension: "410 x 350 x 145 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10009950400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-Elipse 63",
    brand: "Sanitana",
    type: "Vasque à Poser ELIPSE 63",
    dimension: "630 x 430 x 190 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10065350400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-Helm Carre",
    brand: "Sanitana",
    type: "Vasque à Poser Carré Sans Troue de Robinetterie",
    dimension: "470 x 275 x 100 mm",
    Couleur: "Blanc Pur",
    Matiere: "SOLIDE SURFACE",
    Code: "S20208300000000",
    new: true,
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-Helm Rond",
    brand: "Sanitana",
    type: "Vasque à Poser Ronde Sans Troue de Robinetterie",
    dimension: "470 x 275 x 100 mm",
    Couleur: "Blanc Pur",
    Matiere: "SOLIDE SURFACE",
    Code: "S20208400000000",
    new: true,
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-Orbit 44",
    brand: "Sanitana",
    type: "Vasque à Poser 44 Sans Troue de Robinetterie",
    dimension: "440 x 500 x 160 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10013050400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-URBAN",
    brand: "Sanitana",
    type: "Vasque à Poser 45 Avec Troue de Robinet",
    dimension: "450 x 450 x 165 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10016917600000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-URBAN 45",
    brand: "Sanitana",
    type: "Vasque à Poser 45 Avec Troue de Robinet",
    dimension: "450 x 450 x 140 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10016950400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-Trik 73",
    brand: "Sanitana",
    type: "Vasque à Poser TRIK 73",
    dimension: "730 x 440 x 170 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10065350400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-UP 40 Carre",
    brand: "Sanitana",
    type: "Vasque à Poser 40 Carré",
    dimension: "395 x 395 x 120 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10084450400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-Up 40 Circulaire",
    brand: "Sanitana",
    type: "Vasque à Poser 40 Circulaire",
    dimension: "395 x 395 x 120 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10084250400000",
    promo: true,
  },

  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-UP Ovale",
    brand: "Sanitana",
    type: "Vasque à encastrer UP 60",
    dimension: "593 x 395 x 120 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10084350400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a poser",
    articleName: "vasque a poser-UP Rectangulaire",
    brand: "Sanitana",
    type: "Vasque à Poser 40 Rectangulaire",
    dimension: "593 x 395 x 120 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10084550400000",
  },
  /*---------------------------------------------vasque a encastrer---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a encastrer",
    articleName: "vasque a encastrer-Indy 120 cm",
    brand: "Sanitana",
    type: "Vasque à encastrer INDY 1200",
    dimension: "1200 x 440 x 170 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10010550400000",
    promo: true,
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a encastrer",
    articleName: "vasque a encastrer-Indy 90 cm",
    brand: "Sanitana",
    type: "Vasque à encastrer INDY 900",
    dimension: "900 x 400 x 144 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10010550400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a encastrer",
    articleName: "vasque a encastrer-up circulaire 40",
    brand: "Sanitana",
    type: "Vasque à encastrer CIRCLE 40",
    dimension: "395 x 395 x 140 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10063450400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a encastrer",
    articleName: "vasque a encastrer-Ovalo 64",
    brand: "Sanitana",
    type: "Vasque à encastrer OVALO 64",
    dimension: "635 x 390 x 140 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10063550400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a encastrer",
    articleName: "vasque a encastrer-jara",
    brand: "Sanitana",
    type: "Vasque à encastrer JARA 40",
    dimension: "395 x 395 x 190 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10064150400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a encastrer",
    articleName: "vasque a encastrer-Aida",
    brand: "Sanitana",
    type: "Vasque à encastrer par le dessous AIDA 55",
    dimension: "545 x 430 x 157 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10060030500000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a encastrer",
    articleName: "vasque a encastrer-BE YOU",
    brand: "Sanitana",
    type: "Vasque à encastrer BE YOU 60",
    dimension: "600 x 460 x 120 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10062116400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "vasque a encastrer",
    articleName: "vasque a encastrer-Sans troue BE YOU",
    brand: "Sanitana",
    type: "Vasque à encastrer BE YOU 50",
    dimension: "500 x 350 x 120 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10061850400000",
  },
  /*--------------------------------------------- plan vasque---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "plan vasque",
    articleName: "plan vasque-SMILE",
    brand: "Sanitana",
    type: "Plan Vasque SMILE",
    dimension: "1010 x 460 x 185 mm <br> 910 x 460 x 185 mm <br> 810 x 460 x 185 mm <br> 610 x 460 x 185 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10015616400000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "plan vasque",
    articleName: "plan vasque-SMile Double 120",
    brand: "Sanitana",
    type: "Plan Vasque SMILE",
    dimension: "1210 x 450 x 165",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10015724200000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "plan vasque",
    articleName: "plan vasque-Lavabo Aida 65",
    brand: "Sanitana",
    type: "Plan Vasque AIDA",
    dimension: "650 x 500 x 132",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10060336200000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "plan vasque",
    articleName: "plan vasque-vasque a encastrer Velvet",
    brand: "Sanitana",
    type: "Plan Vasque Velvet",
    dimension: "1202 x 458 x 12 mm",
    Couleur: "Blanc Pur",
    Matiere: "Solid Surface",
    Code: "S20003311700000",
  },
  /*--------------------------------------------- lavabo et lave main---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "lavabo et lave mains",
    articleName: "lavabo et lave mains-Lavabo SHAPE",
    brand: "Sanitana",
    type: "Lavabo Suspendue SHAPE",
    dimension: "515 x 480 x 600 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10015117200000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "lavabo et lave mains",
    articleName: "lavabo et lave mains-Lavabo Mural DROP",
    brand: "Sanitana",
    type: "Lavabo Suspendue DROP",
    dimension: "700 x 350 x 154 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10010017200000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "lavabo et lave mains",
    articleName: "lavabo et lave mains-lavabo munique",
    brand: "Sanitana",
    type: "Lavabo Suspendue munique",
    dimension: "560 x 435 x 205 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10012217200000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "lavabo et lave mains",
    articleName: "lavabo et lave mains-GALA Lavabo mural asymetrie",
    brand: "Sanitana",
    type: "Lavabo Suspendue GALA",
    dimension: "800 x 315 x 180 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10012217200000",
    promo: true,
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "lavabo et lave mains",
    articleName: "lavabo et lave mains-lavabo isla",
    brand: "Sanitana",
    type: "Lavabo Suspendue isla",
    dimension: "395 x 505 x 190 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10066117200000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "lavabo et lave mains",
    articleName: "lavabo et lave mains-lavabo city",
    brand: "Sanitana",
    type: "Lavabo Suspendue city",
    dimension: "480 x 480 x 180 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10009736200000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "lavabo et lave mains",
    articleName: "lavabo et lave mains-lavabo be you",
    brand: "Sanitana",
    type: "Lavabo Suspendue be you",
    dimension: "460 x 420 x 170 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10200617200000",
  },
  /*--------------------------------------------- bidet suspendu---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "bidet suspendu",
    articleName: "bidet suspendu-munique",
    brand: "Sanitana",
    type: "bidet suspendu munique",
    dimension: "370 x 540 x 415 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10072734900000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "bidet suspendu",
    articleName: "bidet suspendu-be you",
    brand: "Sanitana",
    type: "bidet suspendu be you",
    dimension: "370 x 540 x 400 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10062734900000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "bidet suspendu",
    articleName: "bidet suspendu-Glam",
    brand: "Sanitana",
    type: "bidet suspendu Glam",
    dimension: "530 x 365 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10069034900000",
  },
  /*--------------------------------------------- urinoir ---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "urinoir",
    articleName: "urinoir-mar",
    brand: "Sanitana",
    type: "Urinoirs Avec Alimentation Horizontale",
    dimension: "315 x 295 x 515 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10072130200000",
  },
  {
    famille: "salles de bain",
    categorie: "sanitaire ceramique",
    sousCategorie: "urinoir",
    articleName: "urinoir-mini",
    brand: "Sanitana",
    type: "Urinoirs Avec Alimentation Verticale",
    dimension: "345 x 265 x 440 mm",
    Couleur: "Blanc Pur",
    Matiere: "Céramique",
    Code: "S10072230600000",
  },
  /*--------------------------------------------- Bains---------------------------------------------------*/
  /*--------------------------------------------- Baignoire rectangulaire---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire rectangulaire",
    articleName: "baignoire rectangulaire-cubic",
    brand: "Sanitana",
    type: "Baignoire Acrylique Rectangulaire",
    dimension: `1800 x 800 mm <br> 1700 x 750 mm `,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20024900000000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire rectangulaire",
    articleName: "baignoire rectangulaire-HELENA",
    brand: "Sanitana",
    type: "Baignoire Acrylique Rectangulaire",
    dimension: `1700 x 700 mm <br> 1700 x 750 mm `,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20030100000000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire rectangulaire",
    articleName: "baignoire rectangulaire-QUATTRO",
    brand: "Sanitana",
    type: "Baignoire Acrylique Rectangulaire",
    dimension: `1800 x 1000 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20038100000000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire rectangulaire",
    articleName: "baignoire rectangulaire-RAQUEL",
    brand: "Sanitana",
    type: "Baignoire Acrylique Rectangulaire",
    dimension: `1800 x 900 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20038300000000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire rectangulaire",
    articleName: "baignoire rectangulaire-VITA",
    brand: "Sanitana",
    type: "Baignoire Acrylique Rectangulaire",
    dimension: `1900 x 900 mm <br> 1800 x 900 mm <br> 1800 x 800 mm `,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S200473000000000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire rectangulaire",
    articleName: "baignoire rectangulaire-ROSARIO",
    brand: "Sanitana",
    type: "Baignoire Acrylique Rectangulaire",
    dimension: `1800 x 800 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20041600000000",
  },
  /*--------------------------------------------- Baignoire coin---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire coin",
    articleName: "baignoire coin-NEX D'ANGLE",
    brand: "Sanitana",
    type: "Baignoire Acrylique D’angle",
    dimension: `1600 x 1000 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20035900000000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire coin",
    articleName: "baignoire coin-RUTE",
    brand: "Sanitana",
    type: "Baignoire Acrylique D’angle",
    dimension: `1370 x 1370 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20041873000000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire coin",
    articleName: "baignoire coin-oasis",
    brand: "Sanitana",
    type: "Baignoire Acrylique D’angle",
    dimension: `1300 x 1300 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20036973000000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire coin",
    articleName: "baignoire coin-CLAUDIA",
    brand: "Sanitana",
    type: "Baignoire Acrylique D’angle",
    dimension: `1400 x 1400 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20023172300000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire coin",
    articleName: "baignoire coin-ISABEL",
    brand: "Sanitana",
    type: "Baignoire Acrylique D’angle",
    dimension: `1500 x 1500 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20030372300000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire coin",
    articleName: "baignoire coin-MARGARIDA",
    brand: "Sanitana",
    type: "Baignoire Acrylique D’angle",
    dimension: `1450 x 1450 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20034772300000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire coin",
    articleName: "baignoire coin-AREA",
    brand: "Sanitana",
    type: "Baignoire Acrylique D’angle",
    dimension: `1400 x 1400 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20022172300000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire coin",
    articleName: "baignoire coin-TREVO",
    brand: "Sanitana",
    type: "Baignoire Acrylique D’angle",
    dimension: `1350 x 1350 mm`,
    Couleur: "Blanc Pur",
    Matiere: "Acrylique",
    Code: "S20044973000000",
  },
  /*--------------------------------------------- Baignoire centrée---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire centree",
    articleName: "baignoire centree-QUEEN",
    brand: "Sanitana",
    type: "Baignoire Centrée",
    dimension: `1800 x 800 mm`,
    Couleur: "Noir brillant",
    Matiere: "en acier émaillé",
    Code: "S30002312105600",
    new:true
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire centree",
    articleName: "baignoire centree-ZAHA",
    brand: "Sanitana",
    type: "Baignoire Centrée",
    dimension: `1800 x 800 mm`,
    Couleur: "Blanc",
    Matiere: "Acrylique ",
    Code: "S20201900000000",
  },
  {
    famille: "salles de bain",
    categorie: "bains",
    sousCategorie: "baignoire centree",
    articleName: "baignoire centree-aman",
    brand: "Sanitana",
    type: "Baignoire Centrée",
    dimension: `1800 x 800 mm`,
    Couleur: "Blanc",
    Matiere: "Solide Surface",
    Code: "S20201700000000",
  },
  /*--------------------------------------------- douche---------------------------------------------------*/
  /*--------------------------------------------- cabine---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "cabine",
    articleName: "cabine-FEAS C1D",
    systeme : "FACE AVANT, 1 FIXE 1 PANNEAUX COULISSANTS",
    hauteur : "180 CM", 
    verre:"<span style='color:darkcyan'> -> VERRE DE SÉCURITÉ STANDART :</span> PLAT VERRE TREMPÉ <br><span style='color:darkcyan'> -> VERRE DE SÉCURITÉ ÉPAISSEUR :</span> 5MM",
    pCouleurs : "BLANC, MAT, BRILLANT",
    dimension: `1200 x 800 mm`,
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "cabine",
    articleName: "cabine-FEAS CE",
    systeme : "D'ENTRÉE D'ANGLE, 1 FIXE 1 PANNEAU COULISSANT DE CHAQUE CÔTÉ ",
    hauteur : "180 CM", 
    verre:"<span style='color:darkcyan'> -> VERRE DE SÉCURITÉ STANDART :</span> VERRE PLAT <br><span style='color:darkcyan'> -> MOTIFS  :</span> LIGNE, VAGUE, TIRANTS, LIGNE LARGE, COUPE VERTICALE LIGNE <br><span style='color:darkcyan'> -> VERRE DE SÉCURITÉ ÉPAISSEUR :</span> 5MM",
    pCouleurs : "BLANC, MAT, BRILLANT",
    dimension: `800 x 800 mm`,
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "cabine",
    articleName: "cabine-FEAS QD",
    systeme : " 2 FIXE 2 PANNEAU COULISSANT ",
    hauteur : "180 CM", 
    verre:"<span style='color:darkcyan'> -> MOTIFS  :</span> LIGNE, VAGUE, TIRANTS, LIGNE LARGE, COUPE VERTICALE LIGNE  <br><span style='color:darkcyan'> -> VERRE DE SÉCURITÉ ÉPAISSEUR :</span> 5MM",
    pCouleurs : "BLANC, MAT, BRILLANT",
    dimension: `900 x 900 mm`,
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "cabine",
    articleName: "cabine-FEAS MARMARA I",
    systeme : "  D'ENTRÉE D'ANGLE, 1 FIXE 1 PANNEAU COULISSANT DE CHAQUE CÔTÉ ",
    hauteur : "180 CM", 
    verre:"<span style='color:darkcyan'> -> VERRE POLISTRILEN :</span> PLUIE  <br><span style='color:darkcyan'> -> VERRE POLISTRILEN :</span> 2MM",
    pCouleurs : "BLANC",
    dimension: `900 x 900 mm <br> 800 x 800 mm <br> 700 x 700 mm`,
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "cabine",
    articleName: "cabine-FEAS MARMARA II",
    systeme : " 2 FIXES 2 PANNEAUX COULISSANTS ",
    hauteur : "180 CM", 
    verre:"<span style='color:darkcyan'> -> VERRE POLISTRILEN :</span> PLUIE  <br><span style='color:darkcyan'> -> VERRE POLISTRILEN :</span> 2MM",
    pCouleurs : "BLANC",
    dimension: `800 x 800 mm`,
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "cabine",
    articleName: "cabine-FEAS C2D",
    systeme : "FACE AVANT, 1 FIXE 1 PANNEAUX COULISSANTS ",
    hauteur : "180 CM", 
    verre:"<span style='color:darkcyan'> -> VERRE DE SÉCURITÉ STANDART :</span> PLAT VERRE TREMPÉ <br><span style='color:darkcyan'> -> VERRE DE SÉCURITÉ ÉPAISSEUR :</span> 5MM",
    pCouleurs : "BLANC, MAT, BRILLANT",
    dimension: `1200 x 900 mm`,
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "cabine",
    articleName: "cabine-smart wellness",
    brand: "Sanitana",
    type: "Cabine de Douche Hydromassantes",
    dimension: ` 900 x 750 x 2135 mm`,
    Couleur: "Aluminium / Verres",
    Matiere: " Noir et Blanc",
    Code: "S20042660612600",
    verre:"<span style='color:darkcyan'> -> Epaisseur :</span> 6 mm ",
  },
  /*--------------------------------------------- paroi de douche---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "paroi de douche",
    articleName: "paroi de douche-Relax 2 P1 T",
    systeme : "PANNEAUX PIVOTANTS ",
    hauteur : "190 CM", 
    verre:"<span style='color:darkcyan'> -> VERRE DE SÉCURITÉ STANDARD :</span>  VERRE TREMPE PLATS <br><span style='color:darkcyan'> -> VERRE DE SÉCURITÉ ÉPAISSEUR :</span> 6MM",
    pCouleurs : "BRILLANTES",
    dimension: `900 x 1900 mm`,
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "paroi de douche",
    articleName: "paroi de douche-STUDIO P2K",
    systeme : "1 FIXE 1 PANNEAUX BASCULANTS ",
    hauteur : "150 CM", 
    verre:"<span style='color:darkcyan'> -> VERRE DE SÉCURITÉ STANDARD :</span>  VERRE PLATS <br><span style='color:darkcyan'> -> VERRE DE SÉCURITÉ ÉPAISSEUR :</span> 6MM",
    pCouleurs : "BRILLANTES",
    dimension: `900 x 1500 mm`,
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "paroi de douche",
    articleName: "paroi de douche-STUDIO QK",
    systeme : "2 FIXE 2 PANNEAUX PIVOTANTS ",
    hauteur : "150 CM", 
    verre:"<span style='color:darkcyan'> -> VERRE DE SÉCURITÉ STANDARD :</span>  VERRE BOMBE <br><span style='color:darkcyan'> -> VERRE DE SÉCURITÉ ÉPAISSEUR :</span> 6MM",
    pCouleurs : "BRILLANTES",
    dimension: `1200 x 1500 mm`,
  },
  /*--------------------------------------------- receveur de douche---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "receveur de douche",
    articleName: "receveur de douche-rocks",
    brand: "Sanitana",
    type: "Receveur de Douche",
    dimension: `1200 x 800 x 30 mm`,
    Couleur: "Noir / Blanc",
    Matiere: "STONEX",
    Code: "SP3014B0320100560",
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "receveur de douche",
    articleName: "receveur de douche-VITA",
    brand: "Sanitana",
    type: "Receveur de Douche",
    dimension: `1500 x 800 x 55 mm`,
    Couleur: "Blanc",
    Matiere: "Acrylique",
    Code: "S20207600006400",
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "receveur de douche",
    articleName: "receveur de douche-JULIA",
    brand: "Sanitana",
    type: "Receveur de Douche",
    dimension: `900 x 900 x 75 mm`,
    Couleur: "Blanc",
    Matiere: "Acrylique",
    Code: "S20031672500000",
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "receveur de douche",
    articleName: "receveur de douche-ANABELA",
    brand: "Sanitana",
    type: "Receveur de Douche",
    dimension: `900 x 900 x 75 mm <br> 700 x 700 x 80 mm <br>800 x 800 x 80 mm`,
    Couleur: "Blanc",
    Matiere: "Céramique",
    Code: "TN1000426370000",
  },
  /*--------------------------------------------- colonne de douche---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "colonne de douche",
    articleName: "colonne de douche-Hydromassage BEAT",
    brand: "Sanitana",
    type: "Colonne de Douche",
    dimension: `275 x 425 x 1355 mm `,
    Couleur: "Noir et Blanc",
    Matiere: "Solid Surface",
    Code: "S20022234200000",
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "colonne de douche",
    articleName: "colonne de douche-Colonne Hydromassantes ACQUA",
    brand: "Sanitana",
    type: "Colonne de Douche",
    dimension: `255 x 400 x 1500 mm`,
    Couleur: "Chromé et Noir",
    Matiere: "Acier Inoxydable / Laiton Lacqué",
    Code: "S20021434105600",
    new:true
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "colonne de douche",
    articleName: "colonne de douche-Colonnes de Douche EDGE",
    brand: "Sanitana",
    type: "Colonnes de Douche Hydromassantes",
    dimension: `275 x 365 x 1625 mm`,
    Couleur: " Float / Noir",
    Matiere: "Acier inoxydable avec Façade Verre tempré de Sécurité",
    Code: "S20025234300000",
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "colonne de douche",
    articleName: "colonne de douche-Colonnes de Douche YN BLACK",
    brand: "Sanitana",
    type: "Colonnes de Douche Hydromassantes",
    dimension: `300 x 480 x 1480 mm`,
    Couleur: "Noir",
    Matiere: "Acier inoxydable",
    Code: "S20048034505600",
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "colonne de douche",
    articleName: "colonne de douche-Colonnes de Douche FLEX SQUARE",
    brand: "Sanitana",
    type: "Colonnes de Douche Hydromassantes",
    dimension: `255 x 455 x 1410 mm `,
    Couleur: "Noir / Argent / Blanc ",
    Matiere: "Dibond",
    Code: "S20027933805600",
  },
  {
    famille: "salles de bain",
    categorie: "douche",
    sousCategorie: "colonne de douche",
    articleName: "colonne de douche-Colonnes de Douche BLOOM",
    brand: "Sanitana",
    type: "Colonnes de Douche",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé",
    Code: "S50207446312600",
  },
  /*--------------------------------------------- robinetterie---------------------------------------------------*/
  /*--------------------------------------------- migiteur lavabo---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-SELEKT",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé/ Plaqué noir mat",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005313350703",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-BLOOM",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50203850212600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-SUN",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50204550212600",
    new: true,
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-SUN BLACK",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Noir Mat",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50204550271600",
    new: true,
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-SILK",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005412750703",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-VIVA",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50202450212600",
  },

  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-XS",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50201950212600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-BALANCE",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50004012950703",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-Bec Long BALANCE",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S5004012950711",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-Bec Long BLOOM",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50203812412600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-Bec Long SUN",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "NOIR MAT",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50204512471600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-Bec Long SILK",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005412750711",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-à encastrer SILK (bec Long)",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005410850700",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-à encastrer SILK (bec Court)",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005411050700",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-à encastrer BALANCE",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50004010950707",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur lavabo",
    articleName: "mitigeur lavabo-à encastrer 22 CM BALANCE",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50004010750707",
  },
  
  /*--------------------------------------------- migiteur douche---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur douche",
    articleName: "mitigeur douche-SELEKT",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005313850702",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur douche",
    articleName: "mitigeur douche-BLOOM",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50204166212600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur douche",
    articleName: "mitigeur douche-BALANCE",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50004013750702",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur douche",
    articleName: "mitigeur douche-SUN",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: " Plaqué noir mat",
    Code: "S50204766271600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur douche",
    articleName: "mitigeur douche-SILK",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005413850702",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur douche",
    articleName: "mitigeur douche-(1 fonction) SILK",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005469450706",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur douche",
    articleName: "mitigeur douche-(2 fonction) SILK",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005470350706",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur douche",
    articleName: "mitigeur douche-(4 fonction) SILK",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005470550706",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur douche",
    articleName: "mitigeur douche-VIVA",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50203013912600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur douche",
    articleName: "mitigeur douche-XS",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50202113712600",
  },
  /*--------------------------------------------- migiteur Baignoire---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur baignoire",
    articleName: "mitigeur baignoire-SELEKT",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005313850705",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur baignoire",
    articleName: "mitigeur baignoire-SILK",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50005413850705",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur baignoire",
    articleName: "mitigeur baignoire-BALANCE",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50004013850705",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur baignoire",
    articleName: "mitigeur baignoire-SUN",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50204866212600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur baignoire",
    articleName: "mitigeur baignoire-SUN noir",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Plaqué noir Mat",
    Code: "S50204866271600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur baignoire",
    articleName: "mitigeur baignoire-BLOOM",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50204178012600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur baignoire",
    articleName: "mitigeur baignoire-VIVA",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50202913912600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur baignoire",
    articleName: "mitigeur baignoire-XS",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50202213712600",
  },
  {
    famille: "salles de bain",
    categorie: "robinetterie",
    sousCategorie: "mitigeur baignoire",
    articleName: "mitigeur baignoire-Sur pied BALANCE",
    brand: "Sanitana",
    type: "Robinetterie ",
    Couleur: "Chromé",
    Matiere: "Laiton Chromé avec cartouche écologique",
    Code: "S50004013750708",
  },
  /*--------------------------------------------- boite support mécanisme de chasse---------------------------------------------------*/
  /*--------------------------------------------- bati support---------------------------------------------------*/
  {
    famille: "salles de bain",
    categorie: "boite support mecanisme de chasse",
    sousCategorie: "bati support",
    articleName: "bati support-INSIDE",
    brand: "Sanitana",
    type: "Collections Sanitaires",
    dimension: `476 x 92 x 892 mm`,
    Matiere: "en acier galvanisé",
    Code: "S10200458500000",
  },
  {
    famille: "salles de bain",
    categorie: "boite support mecanisme de chasse",
    sousCategorie: "bati support",
    articleName: "bati support-Support LCN",
    brand: "Sanitana",
    type: "Collections Sanitaires",
    dimension: `520 x 30-80 x 735 mm`,
    Matiere: "en acier galvanisé",
    Code: "S10096379299900",
  },
  /*--------------------------------------------- cuisine---------------------------------------------------*/

  /*--------------------------------------------- evier cuisine---------------------------------------------------*/
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-lumia 1150 champagne",
  },
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-lumia 115 double bac",
  },
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-lumia 1150 blanc",
  },
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-lumia 1150 gris clair",
  },
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-lisbon 1150",
  },
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-lisbon 1150 materiau resistant",
  },
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-lisbon 1150 double bac",
  },
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-evier en inox 50x116",
  },
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-evier en inox 43.5x86",
  },
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-evier en inox 44x44",
  },
  {
    famille: "cuisine",
    categorie: "evier cuisine",
    sousCategorie: "evier cuisine",
    articleName: "evier cuisine-evier en inox c.48",
  },
  /*--------------------------------------------- mitigeur cuisine---------------------------------------------------*/
  {
    famille: "cuisine",
    categorie: "mitigeur cuisine",
    sousCategorie: "mitigeur cuisine",
    articleName: "mitigeur cuisine-viva",
    type: "Robinetterie",
    Couleur: "Chromée",
    brand: "Sanitana",
    Code: "S50203100012600",
  },
  {
    famille: "cuisine",
    categorie: "mitigeur cuisine",
    sousCategorie: "mitigeur cuisine",
    articleName: "mitigeur cuisine-VOLT",
    type: "Robinetterie",
    Couleur: "Chromée",
    brand: "Sanitana",
    Code: "S50206500012600",
  },
  {
    famille: "cuisine",
    categorie: "mitigeur cuisine",
    sousCategorie: "mitigeur cuisine",
    articleName: "mitigeur cuisine-UNIC",
    type: "Robinetterie",
    Couleur: "Chromée",
    brand: "Sanitana",
    Code: "S50005900050704",
  },
  {
    famille: "cuisine",
    categorie: "mitigeur cuisine",
    sousCategorie: "mitigeur cuisine",
    articleName: "mitigeur cuisine-TUBE TECH",
    type: "Robinetterie",
    Couleur: "Chromée",
    brand: "Sanitana",
    Code: "S50005878350704",
  },
  {
    famille: "cuisine",
    categorie: "mitigeur cuisine",
    sousCategorie: "mitigeur cuisine",
    articleName: "mitigeur cuisine-FLOW",
    type: "Robinetterie",
    Couleur: "Chromée",
    brand: "Sanitana",
    Code: "S50004700050704",
  },
  {
    famille: "cuisine",
    categorie: "mitigeur cuisine",
    sousCategorie: "mitigeur cuisine",
    articleName: "mitigeur cuisine-ATLAS 40",
    type: "Robinetterie",
    Couleur: "Chromée",
    brand: "Sanitana",
    Code: "S50003912450704",
  },
  {
    famille: "cuisine",
    categorie: "mitigeur cuisine",
    sousCategorie: "mitigeur cuisine",
    articleName: "mitigeur cuisine-BLOOM",
    type: "Robinetterie",
    Couleur: "Chromée",
    brand: "Sanitana",
    Code: "S50213500012600",
  },
  {
    famille: "cuisine",
    categorie: "mitigeur cuisine",
    sousCategorie: "mitigeur cuisine",
    articleName: "mitigeur cuisine-XS",
    type: "Robinetterie",
    Couleur: "Chromée",
    brand: "Sanitana",
    Code: "S50202300012600",
  },
  /*--------------------------------------------- batiment---------------------------------------------------*/

  /*---------------------------------------------chauffage---------------------------------------------------*/
  {
    famille: "batiment",
    categorie: "chauffage",
    sousCategorie: "chauffage",
    articleName: "chauffage-Radiateur Decoratif NEMO",
    ficheTech:true
  },
  {
    famille: "batiment",
    categorie: "chauffage",
    sousCategorie: "chauffage",
    articleName: "chauffage-Radiateur Decoratif OTTO",
    ficheTech:true
  },
  {
    famille: "batiment",
    categorie: "chauffage",
    sousCategorie: "chauffage",
    articleName: "chauffage-Seche Serviettes Design ELLIPTIC",
    ficheTech:true
  },
  {
    famille: "batiment",
    categorie: "chauffage",
    sousCategorie: "chauffage",
    articleName: "chauffage-Radiateur Decoratif SOHO",
    ficheTech:true
  },
  {
    famille: "batiment",
    categorie: "chauffage",
    sousCategorie: "chauffage",
    articleName: "chauffage-Radiateur Decoratif Nemo Horizontal",
    ficheTech:true
  },
  {
    famille: "batiment",
    categorie: "chauffage",
    sousCategorie: "chauffage",
    articleName: "chauffage-Seche Serviettes Design BRONIX",
    ficheTech:true
  },
  {
    famille: "batiment",
    categorie: "chauffage",
    sousCategorie: "chauffage",
    articleName: "chauffage-Seche Serviettes Design PM",
  },
  {
    famille: "batiment",
    categorie: "chauffage",
    sousCategorie: "chauffage",
    articleName: "chauffage-Seche Serviettes Design GM",
  },
  {
    famille: "batiment",
    categorie: "chauffage",
    sousCategorie: "chauffage",
    articleName: "chauffage-Radiateur Design ondulee",
  },
  {
    famille: "batiment",
    categorie: "chauffage",
    sousCategorie: "chauffage",
    articleName: "chauffage-Radiateur Design facade verre securit",
  },
  /*---------------------------------------------revetement sol et mur---------------------------------------------------*/
  {
    famille: "batiment",
    categorie: "revetement sol et mur",
    sousCategorie: "revetement sol et mur",
    articleName: "revetement sol et mur-Grès à la masse VOLKAS Poli 60x120",
  },


  /*--------------------------------------------- mobilite reduite---------------------------------------------------*/

  /*---------------------------------------------lavabo---------------------------------------------------*/
  {
    famille: "mobilite reduite",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-Lavabo Mobil",
    ficheTech:true
  },
  /*---------------------------------------------cuvette wc---------------------------------------------------*/
  {
    famille: "mobilite reduite",
    categorie: "cuvette wc",
    sousCategorie: "cuvette wc",
    articleName: "cuvette wc-Cuvette Monobloc pour Personne a mobilité réduite",
    ficheTech:true
  },
  {
    famille: "mobilite reduite",
    categorie: "cuvette wc",
    sousCategorie: "cuvette wc",
    articleName: "cuvette wc-Cuvette Suspendue pour Personne a mobilité réduite",
    ficheTech:true
  },
  {
    famille: "mobilite reduite",
    categorie: "cuvette wc",
    sousCategorie: "cuvette wc",
    articleName: "cuvette wc-Cuvette a L'anglaise pour personne a mobilité réduite",
    ficheTech:true
  },
  {
    famille: "mobilite reduite",
    categorie: "cuvette wc",
    sousCategorie: "cuvette wc",
    articleName: "cuvette wc-Cuvette Monobloc avec ouverture Frontale pour Personne à mobilité réduite",
    ficheTech:true
  },
  {
    famille: "mobilite reduite",
    categorie: "cuvette wc",
    sousCategorie: "cuvette wc",
    articleName: "cuvette wc-Cuvette Monobloc Pour personne à mobilité Réduite",
    ficheTech:true
  },
  /*---------------------------------------------accessoires wc---------------------------------------------------*/
  {
    famille: "mobilite reduite",
    categorie: "accessoires",
    sousCategorie: "accessoires",
    articleName: "accessoires-Accessoires pour Personnes a Mobilité Réduites",
  ficheTech:true
  },
  {
    famille: "mobilite reduite",
    categorie: "accessoires",
    sousCategorie: "accessoires",
    articleName: "accessoires-Mitigeur de Douche VIVA avec Levier Médicale",
    brand: "Sanitana",
    type: "Mobilité réduite",
    Couleur: "Chromé",
    Code: "S50203013912601",
  },
  {
    famille: "mobilite reduite",
    categorie: "accessoires",
    sousCategorie: "accessoires",
    articleName: "accessoires-Mitigeur de Lavabo VIVA avec Levier Médicale",
    brand: "Sanitana",
    type: "Mobilité réduite",
    Couleur: "Chromé",
    Code: "S50202450212601",
  },
  {
    famille: "mobilite reduite",
    categorie: "accessoires",
    sousCategorie: "accessoires",
    articleName: "accessoires-Miroir mobile",
  ficheTech:true
  },
  /*--------------------------------------------- serie enfants ---------------------------------------------------*/
  {
    famille: "serie enfants",
    categorie: "serie enfants",
    sousCategorie: "serie enfants",
    articleName: "serie enfants-Serie Enfants",
    ficheTech:true
  },

  /*--------------------------------------------- robinetterie electronique ---------------------------------------------------*/

  /*---------------------------------------------lavabo---------------------------------------------------*/
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-65000 PRESTO NEO Sur Plage",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-57036 PRESTO NEO Sensor mural avec pile",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-57208 PRESTO LINEA TOUCH",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-PRESTO LINEA RAINBOW",
    ficheTech:true,
    new: true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-PRESTO LINEA UNE FINITION POUR CHAQUE SITUATION",
    ficheTech:true,
    new: true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-66000 ROBINET SIMPLE TEMPORISE PRESTO NEO mural",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-57034 PRESTO NEO Sensor sur plage avec pile",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-68402 PRESTO NEO Mitigeur temporise sur plage",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-PRESTO NEO sur plage 7 sec (PRESTOGREEN)",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-68228 PRESTO 7000 sur plage MITIGEUR TEMPORISE",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-64652 PRESTO 705 sur plage ROBINET SIMPLE TEMPORISE",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-63910 PRESTO 704 mural ROBINET SIMPLE TEMPORISE",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-31660 PRESTO 512 S ROBINET SIMPLE TEMPORISE POUR LAVABO",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-75841 SANIFIRST SANTE Mitigeur de lavabo avec commande au coude sans vidage HOPTIM",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-23614 PRESTO 520 ROBINET SIMPLE TEMPORISE POUR LAVABO mural ou au sol",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-23644 PRESTO 530 ROBINET MITIGEUR TEMPORISE mural ou au sol",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-56198 Mitigeur electronique Secteur avec RA Noir et capot or rose Gamme So'o",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-56235 ROBINET SIMPLE ELECTRONIQUE AVEC REHAUSSE Gamme So'o",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "lavabo",
    sousCategorie: "lavabo",
    articleName: "lavabo-31683 PRESTO 712 ROBINET SIMPLE TEMPORISE a commande femorale",
    ficheTech:true
  },
  /*---------------------------------------------douche---------------------------------------------------*/
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-27400 PRESTO DL 400 SE",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-35195 ALPA",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-35402 PRESTO BOX",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-38222 PRESTO P 50 B encastre ROBINET SIMPLE TEMPORISE",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-35816 PRESTO ALPA Robinet mitigeur temporise monocommande",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-27820 PRESTO DL 800 S ROBINET MITIGEUR TEMPORISE",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-35304 ALPA Mitigeur monobloc monocommande mural temporise N, EA 150mm",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-29110 POMME DE DOUCHE Pomme de douche a grille orientable",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-88880 PRESTOTEM 2 Panneau de douche electronique simple pile avec pomme fixe",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-88834 PRESTOTEM 2 Panneau de douche avec PRESTO TOUCH",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-88820 PRESTOTEM 2 Panneau de douche avec robinet temporise PRESTO 50",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "douche",
    sousCategorie: "douche",
    articleName: "douche-88814 PRESTOTEM 2 Panneau de douche avec mitigeur temporise ALPA S",
    ficheTech:true
  },
  /*---------------------------------------------chasse---------------------------------------------------*/
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-14201 PRESTO 1000 XL",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-14301 PRESTO 1000 XL ADVA",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-14906 PRESTO 1000 E ROBINET TEMPORISE POUR CHASSE",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-14109 PRESTO 1000 M ROBINET TEMPORISE POUR CHASSE",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-13940 ROBINET TEMPORISE ANTIVANDALISME PRESTO ECLAIR ENCASTRE",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-13001 ROBINET TEMPORISE POUR CHASSE PRESTO ECLAIR",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-14261 Kit PRESTO P1000 XL ROBINET TEMPORISE pour galerie technique",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-55434 PRESTO 7200 XL N ROBINET ELECTRONIQUE alimentation pile",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-13060 PRESTO ECLAIR XL Robinet temporise mecanique kit traversee de cloison 15 200mm avec RA",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-14252 PRESTO 1000 XL E type ECO 4L ROBINET TEMPORISE",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "chasse",
    sousCategorie: "chasse",
    articleName: "chasse-55478 PRESTO 7200 XL N ROBINET ELECTRONIQUE, alimentation pile, finition noire et or rose",
    ficheTech:true,
  },
  /*---------------------------------------------urinoir---------------------------------------------------*/
  {
    famille: "robinetterie electronique",
    categorie: "urinoir",
    sousCategorie: "urinoir",
    articleName:"urinoir-55444 PRESTO SENSAO 8100N encastre ROBINET ELECTRONIQUE, alimentation secteur",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "urinoir",
    sousCategorie: "urinoir",
    articleName:"urinoir-55370 PRESTO SENSAO 8400 N ROBINET ELECTRONIQUE applique pour alimentation en ligne",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "urinoir",
    sousCategorie: "urinoir",
    articleName:"urinoir-52502 ROBINET ELECTRONIQUE Kit a travers cloison avec robinet d'urinoir electronique",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "urinoir",
    sousCategorie: "urinoir",
    articleName:"urinoir-31880 Kit PRESTO 12 pour galerie technique ROBINET TEMPORISE",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "urinoir",
    sousCategorie: "urinoir",
    articleName:"urinoir-31707 PRESTO 12 NEO Robinet temporise, applique, alimentation equerre, sans raccord",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "urinoir",
    sousCategorie: "urinoir",
    articleName:"urinoir-38335 PRESTO 120 B encastre ROBINET TEMPORISE",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "urinoir",
    sousCategorie: "urinoir",
    articleName:"urinoir-15233 PRESTO 60 B a encastrer ROBINET TEMPORISE",
    ficheTech:true
  },
  /*---------------------------------------------robinetterie professionnelle---------------------------------------------------*/
  {
    famille: "robinetterie electronique",
    categorie: "robinetterie professionnelle",
    sousCategorie: "robinetterie professionnelle",
    articleName: "robinetterie professionnelle-70830 PRESTO CHEF",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "robinetterie professionnelle",
    sousCategorie: "robinetterie professionnelle",
    articleName: "robinetterie professionnelle-85671 ROBINETTERIE DE SECURITE COMBINE DE DOUCHE ET LAVE YEUX DE SECURITE",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "robinetterie professionnelle",
    sousCategorie: "robinetterie professionnelle",
    articleName: "robinetterie professionnelle-85765 LAVE YEUX DE SECURITE MODELE AVEC VASQUE MONTAGE MURAL",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "robinetterie professionnelle",
    sousCategorie: "robinetterie professionnelle",
    articleName: "robinetterie professionnelle-70802 PRESTO CHEF ROBINETTERIE GRANDE CUISINE Melangeur monotrou sur plage",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "robinetterie professionnelle",
    sousCategorie: "robinetterie professionnelle",
    articleName: "robinetterie professionnelle-70808 PRESTO CHEF ROBINETTERIE GRANDE CUISINE Melangeur deux trous sur plage",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "robinetterie professionnelle",
    sousCategorie: "robinetterie professionnelle",
    articleName: "robinetterie professionnelle-70842 PRESTO CHEF ROBINETTERIE GRANDE CUISINE Melangeur mural",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "robinetterie professionnelle",
    sousCategorie: "robinetterie professionnelle",
    articleName: "robinetterie professionnelle-70834 PRESTO CHEF ROBINETTERIE GRANDE CUISINE Mitigeur monocommande sur plage",
    ficheTech:true,
  },
  {
    famille: "robinetterie electronique",
    categorie: "robinetterie professionnelle",
    sousCategorie: "robinetterie professionnelle",
    articleName: "robinetterie professionnelle-55163 ROBINET MITIGEUR ELECTRONIQUE POUR LAVABO PRESTO VOLTA sur plage",
    ficheTech:true,
  },
  /*---------------------------------------------laboratoire---------------------------------------------------*/
  {
    famille: "robinetterie electronique",
    categorie: "laboratoire",
    sousCategorie: "laboratoire",
    articleName:"laboratoire-76027 SANIFIRST Chandelier a un robinet sur table a bec fixe",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "laboratoire",
    sousCategorie: "laboratoire",
    articleName:"laboratoire-77011 SANIFIRST Bac simple blanc sans trop plein 30X30 cm",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "laboratoire",
    sousCategorie: "laboratoire",
    articleName:"laboratoire-77041 SANIFIRST ROBINETTERIE EVACUATION Bac simple blanc avec trop plein 45X45 cm",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "laboratoire",
    sousCategorie: "laboratoire",
    articleName:"laboratoire-76201 SANIFIRST ROBINETTERIE LABORATOIRE Prise a deux robinets a 90",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "laboratoire",
    sousCategorie: "laboratoire",
    articleName:"laboratoire-76056 SANIFIRST Melangeur a deux robinets sur table a bec orientable",
    ficheTech:true
  },
  {
    famille: "robinetterie electronique",
    categorie: "laboratoire",
    sousCategorie: "laboratoire",
    articleName:"laboratoire-76053 SANIFIRST Melangeur entraxe 150 mm sur dosseret a bec orientable par dessus",
    ficheTech:true
  },
];
