export const cardData = [
  {
    title: "salles de bain",
    description: "bla bla bla",
    sousFamille: [
      {
        nom: "sanitaire ceramique",
        sousFamilles: ["cuvette suspendue", "cuvette au sol","vasque a poser","vasque a encastrer"
      ,'plan vasque','lavabo et lave mains','bidet suspendu','urinoir'],
      },
      {
        nom: "bains",
        sousFamilles: ['baignoire rectangulaire','baignoire coin','baignoire centree']
      },
      {
        nom: "douche",
        sousFamilles: ['cabine','paroi de douche','receveur de douche',
      'colonne de douche']
      },
      {
        nom: "robinetterie",
        sousFamilles: ['mitigeur lavabo','mitigeur baignoire',
      'mitigeur douche']
      },
      {
        nom: "boite support mecanisme de chasse",
        sousFamilles: ['','','','','bati support']
      }
    ],
  },
  {
    title: "cuisine",
    description: "bla bla bla",
    sousFamille: [{ nom: "",
    sousFamilles: []},
      {
        nom: "evier cuisine",
        sousFamilles: []
      },
      {
        nom: "mitigeur cuisine",
        sousFamilles: []
      }
    ],
  },
  {
    title: "batiment",
    description: "bla bla bla",
    sousFamille: [ { nom: "",
    sousFamilles: []},{ nom: "",
    sousFamilles: []},
      {
        nom: "chauffage",
        sousFamilles: []
      },
      {
        nom: "revetement sol et mur",
        sousFamilles: []
      }
    ],
  },
  {
    title: "mobilite reduite",
    description: "bla bla bla",
    sousFamille: [{ nom: "",
    sousFamilles: []},{ nom: "",
    sousFamilles: []},{ nom: "",
    sousFamilles: []},
      {
        nom: "lavabo",
        sousFamilles: []
      },
      {
        nom: "cuvette wc",
        sousFamilles: []
      },
      {
        nom: "accessoires",
        sousFamilles: []
      }
    ],
  },
  {
    title: "serie enfants",
    description: "bla bla bla",
    sousFamille: [],
  },
  {
    title: "robinetterie electronique",
    description: "bla bla bla",
    sousFamille: [
      {
        nom: "lavabo",
        sousFamilles: []
      },
      {
        nom: "douche",
        sousFamilles: []
      },
      {
        nom: "chasse",
        sousFamilles: []
      },
      {
        nom: "urinoir",
        sousFamilles: []
      },
      {
        nom: "robinetterie professionnelle",
        sousFamilles: []
      },
      {
        nom: "laboratoire",
        sousFamilles: []
      }
    ],
  },
];
