import * as React from "react";
import "./style.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button } from "@mui/material";

export default function CardProduit() {
 
  return (
    <div className="produitCards">
      {cardData.map((data, index) => (
        <div className="project" key={index}>
          <img src={data.image} alt="Project" className="project-image" />
          <div className="project-info">
            <h3 className="produitTitle">{data.title}</h3>
          </div>
          <div className="project-info2">
            <a
              href={`collections/${data.title}`}
              target="_blank"
              rel="noreferrer"
              className="projectLink"
            >
              <Button
                sx={{
                  color: "white",
                  fontFamily: "Quicksand",
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "11px" },
                }}
                endIcon={<ArrowRightAltIcon />}
              >
                je découvre
              </Button>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
const cardData = [
  {
    title: "salles de bain",
    description: "bla bla bla",
    image: require("../../images/salle de bain.jpg"),
    sousFamille: [
      "sanitaire ceramique",
      "bains",
      "douche",
      "robinette",
      "boite support mécanisme de chasse",
    ],
  },
  {
    title: "cuisine",
    description: "bla bla bla",
    image: require("../../images/cuisine.jpg"),
    sousFamille: ["evier", "mitigeur cuisine"],
  },
  {
    title: "batiment",
    description: "bla bla bla",
    image: require("../../images/batiment.jpg"),
    sousFamille: ["chauffage", "revetement sol et mur"],
  },
  {
    title: "mobilite reduite",
    description: "bla bla bla",
    image: require("../../images/mobilite.jpg"),
    sousFamille: ["lavabo", "cuvette wc", "accessoires"],
  },
  {
    title: "serie enfants",
    description: "bla bla bla",
    image: require("../../images/serie enfants.png"),
    sousFamille: [],
  },
  {
    title: "robinetterie electronique",
    description: "bla bla bla",
    image: require("../../images/robinette.jpg"),
    sousFamille: [
      "lavabo",
      "douche",
      "chasse",
      "urinoir",
      "grand cuisine",
      "laboratoire",
    ],
  },
];
