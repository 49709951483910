import React, { useState,useEffect } from 'react';
import ProductCard from "../ProductCard";
import { produits } from "../../Data/data";
import { Pagination } from '@mui/material';
import './style.css'
export default function PromoProducts() {

  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const updateItemsPerPage = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage(12);
      } else if (windowWidth >= 700) {
        setItemsPerPage(9);
      } else {
        setItemsPerPage(8);
      }
    };

    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);

    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  const filteredProducts = produits.filter((produit) => (produit.promo===true ))



  const indexOfLastCard = currentPage * itemsPerPage;
  const indexOfFirstCard = indexOfLastCard - itemsPerPage;
  const currentCards = filteredProducts.slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
  <div className='PromoProduct'>

      {currentCards.map((produit, index) => (
        <ProductCard
        key={produit.articleName + index}
        categorie={produit.categorie}
        sousCategorie={produit.sousCategorie}
        articleName={produit.articleName}
        ext={produit.ext}
        famille={produit.famille}
        promo={produit.promo}
        new={produit.new}
        />
     ) )}
</div>
{totalPages > 1 && ( 
      <div className='pagination'>
        <Pagination
          count={totalPages}
          page={currentPage}
          shape="rounded"
          variant="outlined"
          onChange={(event, newPage) => handlePageChange(newPage)}
          color="primary"
        />
    </div>
)}
    </div>
  );
}
