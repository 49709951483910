import "./style.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
export default function ProductCard({
  famille,
  articleName,
  categorie,
  sousCategorie,
  promo,
  newProduct,
  code,
  brand
}) {
  
  const categoriee = articleName.split('-')[0];
  const article = articleName.split('-')[1];
  const articledetail = articleName.replace(/-/g, ' ');
  
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 1024,
        lg: 1200,
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <div className="card">
      <a target="_blank" rel="noreferrer" className="cardMediaLink" href={`/collections/${famille}/${categorie}/${sousCategorie}/${articleName}`}>
        <Card
          className="card"
          sx={{
            height: { lg: "340px", md: "300px", sm: "300px", xs: "240px" },
            width: { lg: "260px", md: "220px", sm: "220px", xs: "160px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          elevation={6}
        >
          {promo ? <span className="promoLabel">Promo</span> : <></>}
          {newProduct ? <span className="newLabel">new</span> : <></>}
          {sousCategorie===categoriee?(
          <img
            className="cardMedia"
            src={`https://firebasestorage.googleapis.com/v0/b/sanitek-tn.appspot.com/o/produits%2F${encodeURIComponent(famille)}%2F${encodeURIComponent(categorie)}%2F${encodeURIComponent(articledetail)}.jpg?alt=media`}
            title={articleName}
            alt={articleName}
           
          />):(<></>)}
          <CardContent
            sx={{
              width: "98%",
    height: { lg: "auto", md: "auto", sm: "auto", xs: "auto" },
    position: 'absolute',
    bottom: 0,
    padding:0,
    marginTop:0,
    display: "flex",
    flexDirection:'column',
    flexWrap:'nowrap',
    alignItems: "center",
    justifyContent:'flex-start'
            }}
          >
            <Typography
              gutterBottom
              textTransform="uppercase"
              variant="h6"
              fontSize={{ lg: "12px", md: "11px", sm: "11px", xs: "9px" }}
              color="#000"
              textAlign="center"
              fontFamily="Ubuntu"
              fontWeight={700}
              letterSpacing="1px"
            >
              {article}<br/>
            {/*  {brand} | {code} */}
            </Typography>
          </CardContent>
        </Card>
        </a>
        
        {/* <Dialog
          open={open}
          onClose={handleClose}
          onBackdropClick={handleClose}
          sx={{ zIndex: 9990888 }}
        >
          <img
            className="cardMediaDialog"
            src={`https://firebasestorage.googleapis.com/v0/b/sanitek-tn.appspot.com/o/produits%2F${encodeURIComponent(famille)}%2F${encodeURIComponent(categorie)}%2F${encodeURIComponent(articledetail)}.jpg?alt=media`}
            title={articleName}
            alt={articleName}
           
          />
          <Typography
            gutterBottom
            textTransform="uppercase"
            variant="h6"
            sx={{ fontSize:{ lg: "14px", md: "14px", sm: "14px", xs: "12px" }}}
            color="#000"
            textAlign="center"
            fontFamily="Josefin"
            marginTop={1}
          >
            {articleName}
          </Typography>
          </Dialog>*/}
      </div>
    </ThemeProvider>
  );
}
