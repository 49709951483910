import './App.css';
import Header from './Components/Header';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Products from './Pages/Products';
import Promotions from './Pages/Promotions';
import Fournisseur from './Pages/Fournisseur';
import ProductDetails from './Pages/ProductDetails';
import Contact from './Pages/Contacts'

import ScrollPage from "./Components/scrollpageUp";
import ReactGA from 'react-ga';

ReactGA.initialize('G-H62WN1HJEK');

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/' element={<Home/>}/>     
        <Route path='/collections' element={<Products/>}/>  
        <Route path="/:name/:name/:name" element={<Products/>}/>
        <Route path="/:name/:name/:name/:name" element={<Products/>}/>
        <Route path="/:name/:name" element={<Products/>}/>
        <Route path="/:name/:name/:name/:name/:name" element={<ProductDetails/>}/>
        <Route path='/promotions' element={<Promotions/>}/>  
        <Route path='/fournisseurs&references' element={<Fournisseur/>}/>  
        <Route path='/contacts' element={<Contact/>}/>  
        
         </Routes>
         <ScrollPage/>
        </BrowserRouter>
    </div>
  );
}

export default App;
