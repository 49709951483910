import './style.css';
import React from 'react';
import Footer from '../../Components/Footer';
import PromoProducts from '../../Components/ProductPromo';
import ScrollToTop from '../../Components/scrollup';
import productBand from '../../images/banniere.jpg'
export default function Promotions() {
    
  return (
    <div className='promotions'>
      <img src={productBand} alt="productBand" />
      <div className='promotion'>
      <ScrollToTop/>
      <div className='productContain'>
        <h2 className='pathTitle'>Promotions</h2>
          <PromoProducts/>
          </div>
          </div>
<Footer/>
    </div>
  );
}
