import './style.css';
import React from 'react';
import { Dialog } from '@mui/material';
import { useState } from 'react';
import Footer from '../../Components/Footer';
import ScrollToTop from '../../Components/scrollup';
import sanitana from '../../images/sanitana.png'
import presto from '../../images/presto.png'
import Certif1 from '../../images/certif/certif1.jpg'
import Certif2 from '../../images/certif/certif2.jpg'
import Certif3 from '../../images/certif/certif3.jpg'
import Certif4 from '../../images/certif/certif4.jpg'
import { refprestoData } from '../../Data/refData';
import { refsanitanaData } from '../../Data/refData';
export default function Fournisseur() {
  const [openCertif1, setOpenCertif1] = useState(false);
  const [openCertif2, setOpenCertif2] = useState(false);
  const [openCertif3, setOpenCertif3] = useState(false);
  const [openCertif4, setOpenCertif4] = useState(false);
  const handleOpen = (certifNumber) => {
    switch (certifNumber) {
      case 1:
        setOpenCertif1(true);
        break;
      case 2:
        setOpenCertif2(true);
        break;
      case 3:
        setOpenCertif3(true);
        break;
      case 4:
        setOpenCertif4(true);
        break;
      default:
        break;
    }
  };
  const handleClose = (certifNumber) => {
    switch (certifNumber) {
      case 1:
        setOpenCertif1(false);
        break;
      case 2:
        setOpenCertif2(false);
        break;
      case 3:
        setOpenCertif3(false);
        break;
      case 4:
        setOpenCertif4(false);
        break;
      default:
        break;
    }
  };
 

  return (
    <div className='fournisseur'>
      <ScrollToTop/>
      <div className='frnsContainer'>
      <div className='presentationFrn'>
      <h2>nos fournisseurs</h2>
      
 <div className='frns'>
    <img src={sanitana} alt="sanitana" className="frnsImg1" />
    <p>Sanitana est une entreprise située à Anadia, au Portugal Fondée au 1979. Spécialisé dans le developpement de produits Sanitaires par la salle de bain.Elle a été la premier entreprise portugaise à se consacrer exclusivement à la production d’articles Sanitaire en céramique.
Au fil des ans Sanitana s’est développée pour répondre aux demandes du marché, En élargissant sa capacité de fabrication de ses domaines d’activité.
Sanitana couvre ses produits par une garantie de 5 ans et des normes europeinne.
</p>

 </div>
 <h3 className='certifTitle'>Cerificats sanitana</h3>
 <div className='certificats'>
 <img
            src={Certif1}
            alt='quality'
            className='qualityImg'
            onClick={() => handleOpen(1)}
          />
          <Dialog
            className='dialog'
            open={openCertif1}
            onClose={() => handleClose(1)}
            onBackdropClick={() => handleClose(1)}
            sx={{ zIndex: 999099 }}
          >
            <img
              src={Certif1}
              alt='quality'
              className='qualityImgDialog'
            />
          </Dialog>

          <img
            src={Certif2}
            alt='quality'
            className='qualityImg'
            onClick={() => handleOpen(2)}
          />
          <Dialog
            className='dialog'
            open={openCertif2}
            onClose={() => handleClose(2)}
            onBackdropClick={() => handleClose(2)}
            sx={{ zIndex: 999999 }}
          >
            <img
              src={Certif2}
              alt='quality'
              className='qualityImgDialog'
            />
          </Dialog>
      </div>

      <div className='frns'>
    <img src={presto} alt="presto" className="frnsImg11" style={{marginRight:'50px'}} />
    <p >Depuis 1927, PRESTO Spécialiste de la robinetterie électronique, temporisée et sensitive pour les collectivités.
Il Compte de large gamme de lavabos,Douches,Urinoirs et chasses qui s’installent dans tous les établissements recevant du public .
De plus, le pionnier de la robinetterie professionnelle compte une gamme de robinets grande cuisine ainsi, q’une marque destinée aux établissements de Santé et laboratoires.
Vous trouvez généralement ces robinets dans les hôtels, restaurants,cafés,grandes cuisines,écoles,complexes sportifs… Sans oublier les établissements de santé et laboratoires avec la marque SANIFIRST.

</p>

 </div>
 <h3 className='certifTitle'>Cerificats presto</h3>
 <div className='certificats'>
<img
            src={Certif3}
            alt='quality'
            className='qualityImg'
            onClick={() => handleOpen(3)}
          />
          <Dialog
            className='dialog'
            open={openCertif3}
            onClose={() => handleClose(3)}
            onBackdropClick={() => handleClose(3)}
            sx={{ zIndex: 9900099 }}
          >
            <img
              src={Certif3}
              alt='quality'
              className='qualityImgDialog'
            />
          </Dialog>

          <img
            src={Certif4}
            alt='quality'
            className='qualityImg'
            onClick={() => handleOpen(4)}
          />
          <Dialog
            className='dialog'
            open={openCertif4}
            onClose={() => handleClose(4)}
            onBackdropClick={() => handleClose(4)}
            sx={{ zIndex: 99900999 }}
          >
            <img
              src={Certif4}
              alt='quality'
              className='qualityImgDialog'
            />
          </Dialog>
      </div>
      
      </div>
      <div className='presentationFrn'>
      <h2>nos references</h2>
      <div className='refDetail'>
      <div>
       <h3 className='refTitle'>LES RÉALISATIONS DE SANITEK, EN PARTICULIER SOUS LA MARQUE PRESTO :</h3>
       <div  className='refdetails'>
      {refprestoData.map((ref,index) => (
        <div key={index+ref.title} className='refdiv'>
              <h4>{ref.title}</h4>
              {ref.sousRef.map((sousref,index) => (
              <span key={index+ref.title}>{sousref}</span>
              ))}
        </div>
      ))}
      </div>
      </div>
      <div><br/><br/>
       <h3 className='refTitle'>LES RÉALISATIONS DE SANITEK, EN PARTICULIER SOUS LA MARQUE SANITANA :</h3>
       <div  className='refdetails'>
      {refsanitanaData.map((ref,index) => (
        <div key={index+ref.title} className='refdiv'>
              <h4>{ref.title}</h4>
              {ref.sousRef.map((sousref,index) => (
              <span key={index+ref.title}>{sousref}</span>
              ))}
        </div>
      ))}
      </div>
      </div>
      </div>
      </div>
      </div>
<Footer/>

    </div>
  );
}