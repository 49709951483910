export const refprestoData = [
    {
      title: "Sport",
      sousRef: ['Stade sousse','Swimming pool Tatouine','Picine tataouine', 'Mini Foot'],
    },
    {
        title: "Education",
        sousRef: ['Factulté des Sports','Ecole préparatoire','INSAT'],
      },
      {
        title: "Industriel",
        sousRef: ['Saiph','Factory bic','Technopole el fajja'],
      },
      {
        title: "Commerce",
        sousRef: ['Mall of sousse','Azur city','Parc des exposition'],
      },
      {
        title: "Hôtellerie",
        sousRef: ['Hotel Sousse Pearl Marriott Ressort','Hotel hasdrubal djerba','Hotel sindbad hammamet','Hotel marhaba sousse'],
      },
      {
        title: "Culture",
        sousRef: ['Museum Bardo'],
      },
      {
        title: "Santé",
        sousRef: ['Polyclinique Ibn nafis Sfax','Polyclinique exelence medicale mahdia','Hopital Hedi chaker','Hopital hbib bourguiba',
    'Hopital el rayhan','Hopital Ibn jazar','Hopital Ibn khaldoun'],
      },
      {
        title: "Transport",
        sousRef: ['Airoport Enfidha','Port de la Goulette','Service Station Shell'],
      },
      {
        title: "Administratif",
        sousRef: ['Bureau tritux office','Minister des affaires'],
      },
    
      {
        title: "Défense",
        sousRef: ['Barracks Severals','Protection civil jbel jloud'],
      },
  ];
  
  export const refsanitanaData = [
    {
      title: "Immobiliers",
      sousRef: ['Aswar soukra','Misk elyl','Diar Venus', 'Logement sadik','Résidence Amira','Immobilier La paix'],
    },
    {
        title: "Santé",
        sousRef: ['Polyclinique Okba','Polyclinique exelence médicale','Carthage médicale'],
      },
      
    {
        title: "Centre de loisirs",
        sousRef: ['Epace Kmar'],
      },
      {
        title: "Hôtellerie",
        sousRef: ['Hotel Chams el Hana Sousse','Hotel Golden Tulip','Hotel Rym Beach Djerba','Hotel Sousse Pearl Marriott Ressort'],
      },
  
  {
    title: "Education",
    sousRef: ['Lycée Francaise Gustave Flanber '],
  },
  ];